import React from 'react'
// import { getSiteMaps } from 'lib/get-site-maps'
import { resolveNotionPage } from 'lib/resolve-notion-page'
import { NotionPage } from 'components'
import { PageProps } from 'lib/types'
import { evaluatePageRevalidation } from 'lib/utils'
import * as Sentry from '@sentry/nextjs'

export const getStaticProps = async (context) => {
  const domain = context.params.site
  const urlArray = context.params.pageId

  try {
    const props = (await resolveNotionPage(domain, urlArray)) as PageProps

    const link = redirectSuperLink(props)

    if (props.redirect) {
      console.log('redirecting to', props.redirect.destination)
      return {
        redirect: {
          destination: `/${props.redirect.destination}`,
          from: 'getStaticProps - _sites/[site]/[...pageId].tsx'
        }
      }
    }

    if (link) {
      return {
        redirect: {
          destination: link
        }
      }
    }

    const revalidateNr = evaluatePageRevalidation(props)
    return { props, revalidate: revalidateNr }
  } catch (err) {
    if (err.message.includes('There is no matching site')) {
      return {
        redirect: {
          destination: 'https://create.simple.ink/404-page'
        }
      }
    }
    if (err.message.includes('Notion page not found')) {
      return {
        redirect: {
          destination: 'https://create.simple.ink/404-page-websites'
        }
      }
    }

    // we don't want to publish the error version of this page, so
    // let next.js know explicitly that incremental SSG failed
    console.error('page error', domain, urlArray, err)
    Sentry.captureException(err, {
      extra: {
        domain,
        urlArray,
        from: 'getStaticProps'
      }
    })
    throw err
  }
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true
  }
}

export default function NotionDomainDynamicPage(props) {
  props = { ...props, isSubPage: true }
  const content = <NotionPage {...props} />
  return typeof content === 'object' ? content : null
}

function redirectSuperLink(data) {
  if (!data.recordMap) return

  data = data.recordMap

  for (const key of Object.keys(data.block)) {
    const block = data.block[key].value
    if (!block || (block && block.parent_table !== 'collection')) continue
    const parent = data.collection[block.parent_id]

    if (!parent) return
    if (!parent.value) return
    if (!parent.value.schema) return

    // go through parent schema
    for (const sk in parent.value.schema) {
      const sku = parent.value.schema[sk]
      if (!/^simple:link$/i.test(sku.name)) continue

      // redirect the user to the link in question.
      const link = block.properties[sk][0][0]
      return link
    }
  }
}
